import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ComposedChart } from "recharts";
import { CircularProgress, Typography, Box, Paper, Autocomplete, TextField, Stack } from "@mui/material";
import { getTotalServiceHoursForCustomerInStateByYear } from "../../features/dashboard/actions";
import { readCustomersByState } from "../../features/customers/actions";

const ServiceHoursBy4YearsByStateForCustomerBar = () => {
  const dispatch = useDispatch()
  const [indianStates, setIndianStates] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [graphOptions, setGraphOptions] = useState([]);

  const [selectedIndianState, setSelectedIndianState] = useState({ label: "Maharashtra (MH)", value: "MH" });
  const [selectedCustomer, setSelectedCustomer] = useState();
  const { serviceHoursByCustomerByYear } = useSelector((state) => state.dashboard);


  const { loading: commonLoading, indianStateDropdownOptions } = useSelector((state) => state.common);
  const { loading: customersLoading, customersByState } = useSelector((state) => state.customers);

  useEffect(() => {
    const fetch = async () => {
      dispatch(readCustomersByState({ indianStateCode: selectedIndianState.value }));
      dispatch(getTotalServiceHoursForCustomerInStateByYear({ state: selectedIndianState.value }));
    };

    fetch();
  }, [dispatch, selectedIndianState, selectedCustomer]);

  useEffect(() => {
    if (!commonLoading) setIndianStates(indianStateDropdownOptions);
    if (!customersLoading) {
      const customerDropdownOptions = serviceHoursByCustomerByYear.data.map((customer) => customer.customerId.toString());
      const data = customersByState.dropdownOptions?.filter((option) => {
        return customerDropdownOptions.includes(option.value);
      });
      setCustomerOptions(data);
    }
  }, [commonLoading, customersLoading, indianStateDropdownOptions, customersByState.dropdownOptions]);


  const onIndianStateChange = (event, newValue) => {
    setSelectedIndianState(newValue);
    setGraphOptions(null)
    setSelectedCustomer({
      label: "",
    });
    dispatch(readCustomersByState({ indianStateCode: newValue.value }));
    dispatch(getTotalServiceHoursForCustomerInStateByYear({ state: newValue.value }));
  };
  const onCustomerChange = (event, newValue) => {
    setSelectedCustomer(newValue);
    setGraphOptions(null)
    console.log(serviceHoursByCustomerByYear?.data)
    // dispatch(getTotalServiceHoursForCustomerInStateByYear({ customerId: newValue.value }));
    if (serviceHoursByCustomerByYear.data) {
      const filteredData = serviceHoursByCustomerByYear?.data.filter((item) => item.customerId == newValue.value);
      setGraphOptions(filteredData[0].serviceHours)
    }
  };


  return serviceHoursByCustomerByYear.loading && commonLoading && customersLoading ? (
    <CircularProgress color="secondary" />
  ) : (
    <Box>
      <Paper elevation={8} sx={{ padding: 1 }}>
        <Stack alignItems="center">
          <Typography variant="body1" align="center" sx={{ mb: 2, mt: 2 }}>
            Service Hours Last 5 Years By State For Customer
          </Typography>
          <Autocomplete
            disablePortal
            id="SelectStateServiceHoursBy4YearsByStateForCustomerBar"
            options={indianStates}
            onChange={onIndianStateChange}
            value={selectedIndianState}
            sx={{ width: 350, ml: 2, paddingBottom: 1 }}
            renderInput={(params) => <TextField {...params} label="State" />}
          />
          <br />
          <Autocomplete
            disablePortal
            id="SelectCustomerServiceHoursBy4YearsByStateForCustomerBar"
            options={customerOptions}
            onChange={onCustomerChange}
            value={selectedCustomer ? selectedCustomer : null}
            sx={{ width: 350, ml: 2 }}
            renderInput={(params) => <TextField {...params} label="Customer" />}
          />
        </Stack>
        <ComposedChart
          width={380}
          height={280}
          data={graphOptions}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <Legend content={() => null} />
          <CartesianGrid stroke="#d6d6d6" />
          <XAxis dataKey={"year"} />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="hours_spent" barSize={30} fill="#bd7ebe" />
        </ComposedChart>
      </Paper>
    </Box>
  );
};

export default ServiceHoursBy4YearsByStateForCustomerBar;
