import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button, Typography, Paper, Stack, TextField, Tooltip, Card, Dialog, DialogTitle, DialogContent, DialogActions, Checkbox, Box, Autocomplete,
    IconButton,
} from "@mui/material";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { DndContext, KeyboardSensor, MouseSensor, useSensor, useSensors } from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { arrayMove as dndKitArrayMove } from "@dnd-kit/sortable";
import { MyPointerSensor } from "../../../utils/UtilClasses/DNDSensors";

import { Add as AddIcon, Delete as DeleteIcon, Save as SaveIcon, Edit as EditIcon } from "@mui/icons-material";
import Header from "../../../components/Header";
import DragAndDrop from "../../../components/DragAndDrop";
import { updateTemplate, createTemplate } from "../../../features/templates/actions";
import { getAllTemplates } from "../../../features/common/actions";
const AddEditNewTemplate = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [textSelectionStartIndex, setTextSelectionStartIndex] = useState(null);
    const [selectedSectionIndex, setSelectedSectionIndex] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const lastRef = useRef(null);
    const [errorMsg, setErrorMsg] = useState({
        name: "",
        sections: [],
    });
    const [templateData, setTemplateData] = useState({
        name: "",
        sections: [],
        active: true,
    });
    const { allTemplates, asyncStorageValues } = useSelector((state) => state.common);
    useEffect(() => {
        dispatch(getAllTemplates());
    }, [dispatch]);

    useEffect(() => {
        if (id && allTemplates && allTemplates.data.length) {
            const templateInfo = structuredClone(allTemplates.data.find((item) => item.id === parseInt(id)));
            setErrorMsg({
                name: "",
                sections: templateInfo.sections.map((item) => ({ name: "", tasks: item.tasks.map((task) => ({ task: null })) })),
            });
            if (templateInfo && templateInfo?.sections.length) {
                const formatttedTemplate = {
                    ...templateInfo,
                    sections: templateInfo?.sections.map((section, index) => ({
                        ...section,
                        id: index.toString(),
                        tasks: section.tasks.map((item, index) => ({ ...item, id: index.toString() })),
                    })),
                };
                setTemplateData(formatttedTemplate);
            } else
                setTemplateData({
                    name: "",
                    sections: [],
                });
        }
    }, [allTemplates.data]);

    const arrayMove = (array, oldIndex, newIndex) => {
        return dndKitArrayMove(array, oldIndex, newIndex);
    };
    const sensors = useSensors(
        useSensor(MyPointerSensor, { activationConstraint: { distance: 8 } }),
        useSensor(MouseSensor, { activationConstraint: { distance: 8 } }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const submitTemplateForm = (action, formData) => {
        dispatch(action(formData)).then((templateResp) => {
            if (templateResp && templateResp.error) return toast.error("Something went wrong!");
            if (templateResp && templateResp.payload.data && templateResp.payload.status === 200) {
                if (templateResp.payload.data.status === "409") return toast.warn(templateResp.payload.data.message);
                if (templateResp.payload.data.status === "404") return toast.warn(templateResp.payload.data.message);
                dispatch(getAllTemplates()).then((res) => {
                    toast.success("Template " + (formData.id ? "updated" : "added") + " successfully!");
                    if (!id) navigate(`/edit-template/` + templateResp.payload.data.id, { replace: true });

                });
            } else return toast.error("Something went wrong!");
        });
    };
    const validate = () => {
        setErrorMsg({
            name: templateData.name.trim() ? "" : "Please enter name.",
            sections: templateData.sections.map((section) => ({
                name: section.name.trim() ? "" : "Please enter name.",
                tasks: section.tasks.map((item) => ({
                    task: item.task.trim() ? "" : "Please enter task.",
                })),
            })),
        });
        const validated =
            templateData.name &&
            !templateData.sections.filter((section) => !section.name || section.tasks.filter((task) => !task.task).length).length;
        if (!templateData.sections.length) return toast.warn("Sections are empty!");
        if (templateData.sections.length && !templateData.sections.filter((section) => section.tasks.length).length)
            return toast.warn("Tasks are empty!");
        if (validated) {
            const orderObject = {
                ...templateData,
                periodicity: templateData?.periodicity || asyncStorageValues?.periodicity.none,
                sections: templateData.sections.map((section, sectionindex) => ({
                    ...section,
                    index: sectionindex,
                    tasks: section.tasks.map((task, index) => ({ hasRemarks: true, task: task.task, index })),
                })),
            };
            if (id) {
                orderObject.templateId = parseInt(id);
                submitTemplateForm(updateTemplate, orderObject);
            } else submitTemplateForm(createTemplate, orderObject);
        }
    };
    const handleDragOver = ({ over }) => {
        const overId = over?.id;

        if (!overId) {
            return;
        }
        const overContainer = over.data.current?.sortable.containerId;

        if (!overContainer) {
            return;
        }
    };

    const handleDragEnd = (active, over, sectionIndex) => {
        if (!over) {
            return;
        }
        if (active.id !== over.id) {
            const activeContainer = active.data.current.sortable.containerId;
            const overContainer = over.data.current?.sortable.containerId || over.id;
            const activeIndex = active.data.current.sortable.index;
            const overIndex = over.data.current?.sortable.index || 0;
            let newItems;
            if (activeContainer === overContainer) newItems = arrayMove(templateData.sections[sectionIndex].tasks, activeIndex, overIndex);
            templateData.sections[sectionIndex].tasks = newItems;
            setTemplateData({ ...templateData });
        }
    };
    const handleSectionDragEnd = (active, over) => {
        if (!over) {
            return;
        }
        if (active.id !== over.id) {
            const activeContainer = active.data.current.sortable.containerId;
            const overContainer = over.data.current?.sortable.containerId || over.id;
            const activeIndex = active.data.current.sortable.index;
            const overIndex = over.data.current?.sortable.index || 0;
            let newItems;
            if (activeContainer === overContainer) newItems = arrayMove(templateData.sections, activeIndex, overIndex);
            templateData.sections = newItems;
            setTemplateData({ ...templateData });
        }
    };
    const [currentItem, setCurrentItem] = useState(undefined);
    const [isEditing, setIsEditing] = useState(false);
    const [editingBoxId, setEditingBoxId] = useState(false);
    useEffect(() => {
        const input = document.getElementById(editingBoxId);
        if (input) {
            input.focus();
            input.setSelectionRange(textSelectionStartIndex, textSelectionStartIndex);
        }
    }, [textSelectionStartIndex]);

    const ItemComponent = (item, index, sectionIndex) => {
        return (
            <Stack my={1.5} flexDirection={"row"} justifyContent={"space-between"}>
                <TextField
                    id={"task" + sectionIndex + item.id + index}
                    key={"task" + sectionIndex + item.id + index}
                    value={item.task || ""}
                    autoComplete="false"
                    autoCorrect="false"
                    autoFocus={currentItem === sectionIndex + item.id + index}
                    onFocus={function (e) {
                        var val = e.target.value;
                        e.target.value = "";
                        e.target.value = val;
                    }}
                    size="small"
                    onKeyDown={(e) => {
                        if (e.key == " ") e.stopPropagation();
                    }}
                    onBlur={function (e) {
                        setIsEditing(false);
                        setCurrentItem(undefined);
                    }}
                    multiline
                    label={"Task name"}
                    sx={{ width: "95%" }}
                    onChange={(event) => {
                        setEditingBoxId("task" + sectionIndex + item.id + index);
                        setTextSelectionStartIndex(event.target.selectionStart);
                        setIsEditing(true);
                        setCurrentItem(sectionIndex + item.id + index);
                        templateData.sections[sectionIndex].tasks[index].task = event.target.value;
                        errorMsg.sections[sectionIndex].tasks[index].task = "";
                        setTemplateData({ ...templateData });
                        setErrorMsg({ ...errorMsg });
                    }}
                    error={errorMsg?.sections[sectionIndex]?.tasks.length && errorMsg?.sections[sectionIndex]?.tasks[index]?.task}
                    helperText={errorMsg?.sections[sectionIndex]?.tasks[index]?.task}
                />
                <Tooltip title={"Remove task"}>
                    <IconButton
                        aria-label="delete"
                        color="error"
                        size="small"
                        onClick={() => {
                            templateData.sections[sectionIndex].tasks.splice(index, 1);
                            setTemplateData({ ...templateData });
                        }}
                    >
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </Stack>
        );
    };
    const sectionComponent = (section, sectionIndex) => (
        <Stack my={2} key={sectionIndex} ref={sectionIndex === templateData?.sections.length - 1 ? lastRef : undefined}>
            {/* <Card sx={{ p: 3 }}> */}
            <Stack direction={"row"} sx={{ flex: 1 }} gap={2} flexWrap={"wrap"} mb={1}>
                <Stack sx={{ flex: 5 }}>
                    <TextField
                        id={"section" + sectionIndex}
                        key={"section" + sectionIndex}
                        label="Section name"
                        multiline
                        value={templateData.sections[sectionIndex]?.name}
                        onFocus={function (e) {
                            var val = e.target.value;
                            e.target.value = "";
                            e.target.value = val;
                        }}
                        onKeyDown={(e) => {
                            if (e.key == " ") e.stopPropagation();
                        }}
                        onBlur={function (e) {
                            setIsEditing(false);
                        }}
                        size="small"
                        onChange={(event) => {
                            setIsEditing(true);
                            setEditingBoxId("section" + sectionIndex);
                            setTextSelectionStartIndex(event.target.selectionStart);
                            templateData.sections[sectionIndex].name = event.target.value;
                            errorMsg.sections[sectionIndex].name = "";
                            setTemplateData({ ...templateData });
                            setErrorMsg({ ...errorMsg });
                        }}
                        fullWidth={true}
                        sx={{ minWidth: 250 }}
                        error={errorMsg && errorMsg?.sections[sectionIndex]?.name}
                        helperText={errorMsg?.sections[sectionIndex]?.name}
                    />
                </Stack>
                <Stack direction={"row"} alignItems={"center"} gap={2} sx={{ flex: 1 }}>
                    <Tooltip title={"Add task"}>
                        <Button
                            startIcon={<AddIcon />}
                            sx={{ height: "self", whiteSpace: "nowrap" }}
                            autoCapitalize="none"
                            variant="contained"
                            onClick={() => {
                                templateData.sections[sectionIndex].tasks.push({
                                    task: null,
                                    index: templateData.sections[sectionIndex].tasks.length + "",
                                    id: templateData.sections[sectionIndex].tasks.length + "",
                                });
                                errorMsg.sections[sectionIndex].tasks.push({
                                    task: "",
                                });
                                setTemplateData({ ...templateData });
                                setErrorMsg({ ...errorMsg });
                            }}
                        >
                            New task
                        </Button>
                    </Tooltip>
                    <Tooltip title={"Remove section"}>
                        <DeleteIcon
                            color="error"
                            onClick={() => {
                                setSelectedSectionIndex(sectionIndex);
                                setShowDialog(true);
                            }}
                            sx={{ cursor: "pointer" }}
                        />
                    </Tooltip>
                </Stack>
            </Stack>
            <div>
                <DndContext
                    sensors={sensors}
                    onDragEnd={({ active, over }) => handleDragEnd(active, over, sectionIndex)}
                    onDragOver={handleDragOver}
                >
                    <div style={{}}>
                        <DragAndDrop
                            nestedItem={true}
                            isEditing={isEditing}
                            id={sectionIndex + ""}
                            items={section?.tasks || []}
                            key={sectionIndex + ""}
                            itemComponent={({ item, index }) => ItemComponent(item, index, sectionIndex)}
                        />
                    </div>
                </DndContext>
            </div>
            {/* </Card> */}
        </Stack>
    );
    return (
        <div>
            <div>
                <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
                    <DialogTitle fontWeight={"bold"}>Alert</DialogTitle>
                    <DialogContent>
                        <Typography>You are about to delete the whole section.</Typography>
                        <Typography>Please press "Confirm Delete" to delete the selected section.</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={() => setShowDialog(false)}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                templateData.sections.splice(selectedSectionIndex, 1);
                                setTemplateData({ ...templateData });
                                setShowDialog(false);
                                setSelectedSectionIndex(null);
                            }}
                            color="primary"
                        >
                            Confirm Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <Box sx={{ mx: 2, minWidth: 370 }}>
                <Stack mb={1}>
                    <Card sx={{ p: 2 }}>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} gap={1} flexWrap={"wrap"}>
                            <Stack direction={"row"} gap={2} alignItems={"center"}>
                                {/* <Typography variant="h5">Template </Typography> */}
                                <TextField
                                    label="Template name"
                                    value={templateData.name}
                                    onChange={(event) => setTemplateData({ ...templateData, name: event.target.value })}
                                    error={errorMsg && errorMsg?.name}
                                    helperText={errorMsg?.name}
                                    sx={{ minWidth: 250 }}
                                    multiline
                                    size="small"
                                />
                            </Stack>
                            <Stack ml={2}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    onChange={(event, newValue) => setTemplateData({ ...templateData, periodicity: newValue })}
                                    value={templateData?.periodicity || asyncStorageValues?.periodicity.none}
                                    options={Object.values(asyncStorageValues?.periodicity) || []}
                                    sx={{ minWidth: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Periodicity" />}
                                    size="small"
                                />
                            </Stack>
                            <Stack direction={"row"} gap={2}>
                                <Tooltip title={"Save template"}>
                                    <Button
                                        disabled={allTemplates.isLoading}
                                        startIcon={<SaveIcon />}
                                        sx={{ height: "self" }}
                                        autoCapitalize="none"
                                        variant="contained"
                                        onClick={() => validate()}
                                    >
                                        Save
                                    </Button>
                                </Tooltip>
                            </Stack>
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} mt={2}>
                            <Stack direction={"row"} alignItems={"center"}>
                                <Checkbox
                                    checked={templateData?.active ? true : false}
                                    onChange={(event) => {
                                        setTemplateData({
                                            ...templateData,
                                            active: event.target.checked,
                                        });
                                    }}
                                    inputProps={{ "aria-label": "controlled" }}
                                />
                                {templateData?.active ? (
                                    <Typography>Enabled (Uncheck the box to disable the template)</Typography>
                                ) : (
                                    <Typography>Disabled (check the box to enable the template)</Typography>
                                )}
                            </Stack>
                            <Stack gap={2}>
                                <Tooltip title={"Add section"}>
                                    <Button
                                        startIcon={<AddIcon />}
                                        sx={{ height: "self" }}
                                        autoCapitalize="none"
                                        variant="contained"
                                        onClick={() => {
                                            setTemplateData({
                                                ...templateData,
                                                sections: [
                                                    ...templateData.sections,
                                                    { name: "", tasks: [], index: templateData.sections.length + "", id: templateData.sections.length + "" },
                                                ],
                                            });
                                            setErrorMsg({ ...errorMsg, sections: [...errorMsg.sections, { name: "", tasks: [] }] });
                                            // lastRef.current.scrollIntoView();
                                        }}
                                    >
                                        New section
                                    </Button>
                                </Tooltip>
                            </Stack>
                        </Stack>
                    </Card>
                </Stack>
                <Stack>
                    <div>
                        <DndContext
                            sensors={sensors}
                            onDragEnd={({ active, over }) => handleSectionDragEnd(active, over)}
                        // onDragOver={handleDragOver}
                        >
                            <div>
                                <DragAndDrop
                                    nestedItem={false}
                                    isEditing={isEditing}
                                    id={templateData.name + ""}
                                    items={templateData?.sections || []}
                                    key={templateData.name + ""}
                                    itemComponent={({ item, index }) => sectionComponent(item, index)}
                                />
                            </div>
                        </DndContext>
                    </div>
                </Stack>

                {templateData &&
                    (templateData?.sections.length > 2 || templateData?.sections.filter((section) => section.tasks.length >= 5).length >= 1) ? (
                    <Card sx={{ p: 3 }}>
                        <Stack alignItems={"flex-end"}>
                            <Stack direction={"row"} gap={2}>
                                <Tooltip title={"Add section"}>
                                    <Button
                                        startIcon={<AddIcon />}
                                        sx={{ height: "self" }}
                                        autoCapitalize="none"
                                        variant="contained"
                                        onClick={() => {
                                            setTemplateData({
                                                ...templateData,
                                                sections: [
                                                    ...templateData.sections,
                                                    { name: "", tasks: [], index: templateData.sections.length + "", id: templateData.sections.length + "" },
                                                ],
                                            });
                                            setErrorMsg({ ...errorMsg, sections: [...errorMsg.sections, { name: "", tasks: [] }] });
                                            lastRef.current.scrollIntoView();
                                        }}
                                    >
                                        New section
                                    </Button>
                                </Tooltip>
                                <Tooltip title={"Save template"}>
                                    <Button
                                        disabled={allTemplates.isLoading}
                                        startIcon={<SaveIcon />}
                                        sx={{ height: "self" }}
                                        autoCapitalize="none"
                                        variant="contained"
                                        onClick={() => validate()}
                                    >
                                        Save
                                    </Button>
                                </Tooltip>
                            </Stack>
                        </Stack>
                    </Card>
                ) : null}
            </Box>
        </div>
    );



}

export default AddEditNewTemplate;
