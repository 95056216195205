import { createSlice } from "@reduxjs/toolkit";
import { readAsyncStorageValues, getAllTemplates, getStatesWithServiceTickets } from "./actions";

const initialState = {
  loading: false,
  asyncStorageValues: {},
  allTemplates: {
    data: [],
    loading: false,
  },
  indianStateDropdownOptions: [],
  indianStateDropdownOptionsCodeOnly: [],
  machineModels: [],
  statesWithServiceTickets: [],
  error: null,
  success: false,
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {},
  extraReducers: {
    [readAsyncStorageValues.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [readAsyncStorageValues.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.asyncStorageValues = payload.asyncStorageValues;
      state.indianStateDropdownOptions = payload.indianStateDropdownOptions;
      state.indianStateDropdownOptionsCodeOnly = payload.indianStateDropdownOptionsCodeOnly;
      state.machineModels = payload.machineModels;
      state.appConstants = payload.appConstants;
    },
    [readAsyncStorageValues.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    }, [getAllTemplates.pending]: (state) => {
      state.allTemplates.loading = true;
      state.error = null;

    },
    [getAllTemplates.fulfilled]: (state, { payload }) => {
      state.allTemplates.loading = false;
      state.allTemplates.data = payload.data;

    },
    [getAllTemplates.rejected]: (state, { payload }) => {
      state.allTemplates.loading = false;
      state.error = payload;
    },
    [getStatesWithServiceTickets.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getStatesWithServiceTickets.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.statesWithServiceTickets = payload;
    },
    [getStatesWithServiceTickets.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },

});

export default commonSlice.reducer;
