import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSalesCallsBySalesPerson } from "../../features/sales/actions";
import { DataGrid } from "@mui/x-data-grid";
import { Box,  Button,  Tooltip, Typography,IconButton } from "@mui/material";
import { Autocomplete, TextField, Stack } from "@mui/material";
import { readAsyncStorageValues } from "../../features/common/actions";
import { getAppUsers } from "../../features/users/actions";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import { Search as SearchIcon, Clear as ClearIcon, } from "@mui/icons-material";
import { exportToCsv } from "../../utils/utilFunctions";
import CloseIcon from "@mui/icons-material/Close";


const SalesCalls = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { salesCallsBySalesPerson,  } = useSelector((state) => state.sales);
  const { asyncStorageValues } = useSelector((state) => state.common);
  const { appUsersList } = useSelector((state) => state.users);
  const { userCurrentRole } = useSelector((state) => state.auth);
  const [searchTerm, setSearchTerm] = useState("");
  const [salesUsersList, setSalesUsersList] = useState([]);
  const [selectedSalesperson, setSelectedSalesperson] = useState({
    name: "Select Sales Person",
    userId: null,
  });

  useEffect(() => {
    const fetch = async () => {
      dispatch(readAsyncStorageValues());
      dispatch(getAppUsers());
    };
    fetch();
  }, [dispatch]);

  useEffect(() => {
    if (asyncStorageValues && asyncStorageValues.allTypes && appUsersList.data.length) {
      const roles = asyncStorageValues.allTypes.USER_ROLES;
      const salesUsers = appUsersList?.data
        .filter((obj) => (obj.userRole === roles.ROLE_REGIONAL_SALES_MANAGER || obj.userRole === roles.ROLE_SALES_PERSON) && obj.enabled)
        .sort((a, b) => a.name.localeCompare(b.name));
      if (userCurrentRole === roles.ROLE_REGIONAL_SALES_MANAGER)
        setSalesUsersList(salesUsers.filter((user) => asyncStorageValues?.assignedSaleEngineers.includes(user.userId)));
      else setSalesUsersList(salesUsers);
    }
  }, [appUsersList, asyncStorageValues]);

  const onSalesPersonChange = (event, newValue) => {
    setSelectedSalesperson(newValue);
    if (newValue) dispatch(getSalesCallsBySalesPerson({ salesPersonId: newValue.userId }));
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event?.target?.value || "");
  };

  const handleClearSearch = () => setSearchTerm("");

  const dateComparator = (v1, v2) => moment(v1).diff(moment(v2));
  const gridUserAction = (params) => {
    return (
      <div>
        <Button
          sx={{ mr: 1 }}
          onClick={() => {
            navigate("/sales-calls-details", { state: params.row });
          }}
        >
          <Tooltip title="View all sales calls">
            <Typography color={"#000"} sx={{ textTransform: "capitalize" }}>
              {params?.row?.customerName || "-"}
            </Typography>
          </Tooltip>
        </Button>
      </div>
    );
  };
  const columns = [
    { field: "customerName", headerName: "Customer Name", width: 200, flex: 0.2, renderCell: gridUserAction },
    { field: "customerState", headerName: "State", width: 150, flex: 0.2 },
    { field: "category", headerName: "Category", width: 100, flex: 0.2 },
    { field: "visitedDate", headerName: "Visited Date", width: 100, flex: 0.2, sortComparator: dateComparator },
    { field: "remarks", headerName: "Remarks", width: 150, flex: 0.2 },
  ];


  const downloadCsv = (e) => {
    e.preventDefault();
    const headers = ["Id,Customer Name, State, Visited Date, Category, Opportunity Status, Sales Person, Remarks"];
    const keys = [
      "salesCallId",
      "customerName",
      "customerState",
      "visitedDate",
      "category",
      "opportunityStatus",
      "salesPersonName",
      "remarks",
    ];
    exportToCsv(headers, keys, salesCallsBySalesPerson.data, selectedSalesperson.name + "_Sales_Calls");
  };

  return (
    <div>
      <Stack justifyContent="center" sx={{ mx: 2, my: 5 }}>
        <Stack direction={"row"} sx={{ mx: 3 }} justifyContent={"space-between"}>
          <Stack direction={"row"}>
            <Autocomplete
              disablePortal
              name="SelectSalesperson"
              id="SelectSalesperson"
              options={salesUsersList}
              getOptionLabel={(option) => option.name}
              value={selectedSalesperson}
              onChange={onSalesPersonChange}
              sx={{ width: 400 }}
              // clearIcon={null}
              renderInput={(params) => <TextField {...params} label="Select Sales Person" />}
            />
            <TextField
              style={{ marginLeft: 30, width: 400 }}
              label="Search"
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {searchTerm ? (
                      <IconButton edge="end" onClick={handleClearSearch} size="small">
                        <CloseIcon />
                      </IconButton>
                    ) : (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Button
            variant="contained"
            sx={{ textTransform: "capitalize" }}
            onClick={downloadCsv}
            disabled={salesCallsBySalesPerson.data.length ? false : true}
          >
            Export to CSV
          </Button>
        </Stack>
        <Box style={{ height: 700, width: "100%", paddingLeft: "2%", paddingTop: 3, marginTop: 8, paddingRight: "1%" , overflow: "auto"}}>
          {salesCallsBySalesPerson.data && salesCallsBySalesPerson.data.length > 0 ? (
            <DataGrid
              rows={
                selectedSalesperson?.userId
                  ? salesCallsBySalesPerson.data
                      .filter((item) => Object.values(item).join(" ").toLowerCase().includes(searchTerm.toLowerCase()))
                      .map((item) => ({ ...item, id: item.salesCallId }))
                  : []
              }
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              disableColumnMenu
              style={{ boxShadow: "0px 3px 15px rgba(0,0,0,0.2)" }}
              getRowId={(row) => row.id}
            />
          ) : (
            <div
              style={{
                height: 700,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 7,
              }}
            >
              No Data
            </div>
          )}
        </Box>
      </Stack>
    </div>
  );
};

export default SalesCalls;