import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllTemplates } from "../../features/common/actions";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { Search } from "@mui/icons-material";
import { ZoomIn as ViewIcon, Edit as EditIcon } from "@mui/icons-material";
import { Stack, Autocomplete, TextField, OutlinedInput, IconButton, InputAdornment, Button, Typography, Box, Tooltip, CircularProgress, Dialog, Alert, Card } from "@mui/material";
const Templates = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const { allTemplates, asyncStorageValues } = useSelector((state) => state.common);
  console.warn("async", asyncStorageValues)
  const [searchValue, setSearchValue] = useState("");
  const [templatesList, setTemplatesList] = useState([]);
  useEffect(() => {
    dispatch(getAllTemplates());
  }, [dispatch]);
  useEffect(() => {
    if (allTemplates.data.length && !allTemplates.isLoading) {
      setTemplatesList(allTemplates.data);
      console.log("all", allTemplates.data)
    }
  }, [allTemplates.data]);
  const columns = [
    { field: "name", headerName: "Name", width: 100, flex: 0.1 },
    { field: "periodicity", headerName: "Periodicity", width: 100, flex: 0.1 },
    {
      field: "active", headerName: "Active", width: 150, flex: 0.1,
      valueGetter: (params) => (params.row[params.field] ? "Yes" : "No"),
    },

    {
      sortable: false,
      filterable: false,
      renderCell: (args) => (
        <IconButton sx={{ mr: 1 }} onClick={() => navigate(`/edit-template/` + args.row.id)}>
          <Tooltip title="Edit">
            <EditIcon sx={{ height: "self" }} />
          </Tooltip>
        </IconButton>
      ),
    },
  ];

  return (
    <div>
      <Box sx={{ mx: 2 }}>
        <Stack direction={"row"} alignItems={"center"} my={2} justifyContent={"space-between"}>
          <Stack direction={"row"} gap={1} alignItems={"center"}>
            <Button
              startIcon={<AddIcon />}
              sx={{ height: "self" }}
              autoCapitalize="none"
              variant="contained"
              onClick={() => navigate("/add-template/")}
            >
              ADD
            </Button>
            <OutlinedInput
              id="outlined-adornment-password"
              value={searchValue}
              onChange={(event) => setSearchValue(event.target.value)}
              placeholder="Search..."
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" edge="end">
                    <Search />
                  </IconButton>
                </InputAdornment>
              }
              sx={{ minWidth: 300, maxWidth: 600, height: 35 }}
            />
          </Stack>
        </Stack>
        <Box sx={{ height: 650 }} >
          <DataGrid
            rows={templatesList.filter((item) => Object.values(item).join(" ").toLowerCase().includes(searchValue.toLowerCase()))}
            columns={columns}
            pageSize={5}
            localeText={{ noRowsLabel: "No Data." }}
            style={{ boxShadow: "0px 3px 15px rgba(0,0,0,0.2)", marginLeft: 5 }}
          />
        </Box>
      </Box>
    </div>
  );
};

export default Templates;
