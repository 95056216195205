import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../app/api";

export const getServiceTicketsForTheLastXDays = createAsyncThunk(
  "serviceTickets/getServiceTicketsByStatusList",
  async (days, { rejectWithValue }) => {
    try {
      const { data } = await api.post(`/serviceapi/getServiceTicketsForTheLastXDays?days=${days}`, {});
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getServiceTicketsByStatusList = createAsyncThunk(
  "serviceTickets/getServiceTicketsByStatusList",
  async (payload, { rejectWithValue }) => {

    try {
      const { data } = await api.post("/serviceapi/getServiceTicketsByStatusList", {
        statusList: payload.statusList,
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const readMachinesByCustomerId = createAsyncThunk("customers/readMachinesByCustomerId", async (customerId, { rejectWithValue }) => {
  try {
    const response = await api.get("/serviceapi/readMachinesByCustomerId?id=" + customerId);
   
    if (response?.data) response.data = response?.data.sort((a, b) => b.id - a.id);
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
export const getCustomersWithServiceTickets = createAsyncThunk(
  "customers/getCustomersWithServiceTickets",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`/serviceapi/getCustomersWithServiceTickets`);

      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const readServiceTicket = createAsyncThunk("serviceTickets/readServiceTicket", async (serviceTicketId, { rejectWithValue }) => {
  try {
    const response = await api.get(`serviceapi/readServiceTicket?id=${serviceTicketId}`);
    // console.log("service tkt", response)
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const readServiceTicketMessagesByServiceTicketId = createAsyncThunk("serviceTickets/readServiceTicketMessagesByServiceTicketId", async (serviceTicketId, { rejectWithValue }) => {
  try {
    const { data } = await api.get(`serviceapi/readServiceTicketMessagesByServiceTicketId?id=${serviceTicketId}`);
    // console.log("Messages", data)
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const createServiceTicket = createAsyncThunk("servicetickets/createServiceTicket", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.post("/serviceapi/createServiceTicket", payload);
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
export const updateServiceTicket = createAsyncThunk("servicetickets/updateServiceTicket", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.post("/serviceapi/updateServiceTicket", payload);
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
export const addTextMessageToServiceTicket = createAsyncThunk("servicetickets/addTextMessageToServiceTicket", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.post("/serviceapi/addTextMessageToServiceTicket", payload);
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
export const readServiceTimeLog = createAsyncThunk("servicetickets/readServiceTimeLog", async (serviceTicketId, { rejectWithValue }) => {

  try {
    const { data } = await api.get(`serviceapi/readServiceTimeLog?id=${serviceTicketId}`);
    console.log("read", data)
    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

