import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Typography, Box, Container, useTheme } from "@mui/material";
import {
  HomeOutlined,
  Schedule,
  Person,
  People,
  BarChart,
  Task,
  Call,
  AutoAwesomeMosaic as MachinModelIcon,
  Assignment,
  PictureInPicture
} from "@mui/icons-material";

const Sidebar = (props) => {
  const { pathname } = useLocation();
  const { userInfo, userCurrentRole } = useSelector((state) => state.auth);
  const theme = useTheme();
  const [activeBar, setActiveBar] = useState("");

  useEffect(() => {
    setActiveBar(pathname);
  }, [pathname]);

  const links = {
    ROLE_REGIONAL_SALES_MANAGER: [
      {
        title: "Dashboards",
      },
      {
        title: "National Service Hours",
        path: "/dashboard/last-4-years",
        icon: <BarChart />,
      },
      {
        title: "State Service Hours",
        path: "/",
        icon: <HomeOutlined />,
      },
      {
        title: "Last 12 months",
        path: "/dashboard/last-12-months",
        icon: <Schedule />,
      },
      {
        title: "Service Tickets",
      },
      {
        title: "By Customer",
        path: "/AllServiceTickets",
        icon: <Assignment />,
      },
      {
        title: "All Service Tickets",
        path: "/AllSvcTicketsForLastXdays",
        icon: <Assignment />,
      },

      {
        title: "Data",
      },
      {
        title: "Customers",
        path: "/customers",
        icon: <People />,
      },
      {
        title: "Users",
        path: "/users",
        icon: <Person />,
      },
      {
        title: "Reports",
        path: "/reports",
        icon: <Task />,
      },

      { title: "Sales Calls", path: "/sales-calls", icon: <Call /> },
    ],

    ROLE_NATIONAL_SERVICE_MANAGER: [
      {
        title: "Dashboards",
      },
      {
        title: "National Service Hours",
        path: "/dashboard/last-4-years",
        icon: <BarChart />,
      },
      {
        title: "State Service Hours",
        path: "/",
        icon: <HomeOutlined />,
      },
      {
        title: "Last 12 months",
        path: "/dashboard/last-12-months",
        icon: <Schedule />,
      },
      {
        title: "Service Tickets",
      },
      {
        title: "By Customer",
        path: "/AllServiceTickets",
        icon: <Assignment />,
      },
      {
        title: "All Service Tickets",
        path: "/AllSvcTicketsForLastXdays",
        icon: <Assignment />,
      },

      {
        title: "Data",
      },
      {
        title: "Users",
        path: "/users",
        icon: <Person />,
      },
      {
        title: "Reports",
        path: "/reports",
        icon: <Task />,
      },
      { title: "Sales Calls", path: "/sales-calls", icon: <Call /> },
    ],
    ROLE_SERVICE_COORDINATOR: [
      {
        title: "Dashboards",
      },
      {
        title: "National Service Hours",
        path: "/dashboard/last-4-years",
        icon: <BarChart />,
      },
      {
        title: "State Service Hours",
        path: "/",
        icon: <HomeOutlined />,
      },
      {
        title: "Last 12 months",
        path: "/dashboard/last-12-months",
        icon: <Schedule />,
      },
      {
        title: "Service Tickets",
      },
      {
        title: "By Customer",
        path: "/AllServiceTickets",
        icon: <Assignment />,
      },
      {
        title: "All Service Tickets",
        path: "/AllSvcTicketsForLastXdays",
        icon: <Assignment />,
      },

      {
        title: "Data",
      },
      {
        title: "Users",
        path: "/users",
        icon: <Person />,
      },
      {
        title: "Reports",
        path: "/reports",
        icon: <Task />,
      },
      { title: "Sales Calls", path: "/sales-calls", icon: <Call /> },
    ],

   
    ROLE_DATA_OWNER: [
      {
        title: "Dashboards",
      },
      {
        title: "National Service Hours",
        path: "/dashboard/last-4-years",
        icon: <BarChart />,
      },
      {
        title: "State Service Hours",
        path: "/",
        icon: <HomeOutlined />,
      },
      {
        title: "Last 12 months",
        path: "/dashboard/last-12-months",
        icon: <Schedule />,
      },
      {
        title: "Service Tickets",
      },
      {
        title: "By Customer",
        path: "/AllServiceTickets",
        icon: <Assignment />,
      },
      {
        title: "All Service Tickets",
        path: "/AllSvcTicketsForLastXdays",
        icon: <Assignment />,
      },
      {
        title: "Data",
      },
      {
        title: "Customers",
        path: "/customers",
        icon: <People />,
      },
      {
        title: "Users",
        path: "/users",
        icon: <Person />,
      },
      {
        title: "Reports",
        path: "/reports",
        icon: <Task />,
      },
      {
        title: "Machine Models",
        path: "/machine-model",
        icon: <MachinModelIcon />,
      },
      { title: "Sales Calls", path: "/sales-calls", icon: <Call /> },
      {
        title: "Templates",
        path: "/templates",
        icon: <PictureInPicture />,
      },
    ],
  };

  return (
    <div>
      {userInfo && (
        <Box component="nav" sx={{ width: { sm: props.drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
          <Drawer
            open
            variant="permanent"
            anchor="left"
            transitionDuration={500}
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: props.drawerWidth,
              },
            }}
          >
            <Box width="100%" height={"100%"} sx={{ backgroundColor: theme.palette.primary.main }}>
              <Box m="1rem 1rem 1rem 2rem">
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <Typography variant="h4" fontWeight="bold" color={"white"}>
                    Bindwel
                  </Typography>
                </Box>
              </Box>
              <List>
                {userCurrentRole &&
                  links[userCurrentRole].map((link, index) =>
                    !link.icon ? (
                      <ListItem
                        key={link.title}
                        disablePadding
                        sx={{
                          pr: "2rem",
                          color: "white",
                        }}
                      >
                        <Typography key={link.title} sx={{ m: "1rem 0 1rem 1rem" }} variant="h5" fontWeight="600">
                          {link.title}
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem
                        onClick={() => setActiveBar(link.path)}
                        component={RouterLink}
                        to={link.path}
                        key={index}
                        sx={{
                          textDecoration: "none",
                          color: "white",
                          backgroundColor: link.path === activeBar ? theme.palette.secondary.main : theme.palette.primary.main,
                        }}
                      >
                        <ListItemIcon sx={{ color: "white" }}>{link.icon}</ListItemIcon>
                        <ListItemText primary={link.title} />
                      </ListItem>
                    )
                  )}
              </List>
            </Box>
          </Drawer>
        </Box>
      )}
    </div>
  );
};

export default Sidebar;
