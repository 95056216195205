import axios from "axios";

const backendURL = "https://api.techenablesme.com/clarity/";
 //const backendURL = "http://localhost:8080/techapps/";


const instance = axios.create({
  baseURL: `${backendURL}`,
  headers: {
    "Content-Type": "application/json",
  },
});

const refreshToken = async () => {
  // instance.interceptors.request.use((config) => {
  //   config.headers["Content-Type"] = "application/x-www-form-urlencoded";
  //   return config;
  // });

  const { refresh_token, access_token } = JSON.parse(localStorage.getItem("USER_DETAILS"));
  let formBody = [];
  formBody.push("grant_type=refresh_token");
  formBody.push("refresh_token=" + refresh_token);
  formBody = formBody.join("&");
  // const response = await instance.post("oauth/access_token", formBody);

  const response = await axios.post(`${backendURL}/oauth/access_token`, formBody, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${access_token}`,
    },
  });

  return response;
};

instance.interceptors.request.use(
  async (config) => {
    const userInfo = JSON.parse(localStorage.getItem("USER_DETAILS"));
    if (userInfo?.access_token) config.headers.Authorization = `Bearer ${userInfo.access_token}`;
    return config;
  },
  (err) => {
    console.error(err);
    return Promise.reject(err);
  }
);
instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const response = await refreshToken();
          localStorage.setItem("USER_DETAILS", JSON.stringify(response.data));
          instance.defaults.headers.common["x-access-token"] = response.data.access_token;
          return instance(originalConfig);
        } catch (_error) {
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data);
          }

          return Promise.reject(_error);
        }
      }

      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data);
      }
    }

    return Promise.reject(err);
  }
);

export default instance;
