import React, { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Card } from "@mui/material";
import { DragIndicator as DragIcon } from "@mui/icons-material";

const SortableItem = ({ id, data, index, itemComponent: ItemComponent, nestedItem, isEditing }) => {
    const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } = useSortable({
        id,
        disabled: isEditing && true,
    });

    return (
        <div
            style={{
                transform: CSS.Translate.toString(transform),
                transition,
                marginBottom: 8,
            }}
            ref={setNodeRef}
            {...attributes}
        >
            <Card>
                <div style={{ flexDirection: "row", display: "flex", alignItems: nestedItem ? "center" : "normal" }}>
                    <div style={{ marginRight: 5, marginTop: nestedItem ? 0 : 20, marginLeft: 5, flex: 0.03 }}>
                        <DragIcon {...listeners} node={setActivatorNodeRef} style={{ cursor: "grab" }} fontSize="medium" />
                    </div>
                    <div style={{ flex: 0.95 }}>
                        <ItemComponent item={data} index={index} />
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default SortableItem;
